import React from 'react'
import styled from 'styled-components';
import Profile from '../assets/company_profile.pdf';
import { MdFileDownload } from 'react-icons/md';


const AboutWrapper = styled.div`
  text-align: center;
  align-items: center;
  justify-content: center;
  margin-top : 10px;
  
  @media only screen and (max-width: 480px) {
    text-align: center;
  }
 `;

 const Heading = styled.div`
  
  align-items: center;
  justify-content: center;
  padding: 0.2rem;
  background-color: #00008B;
  color: #fff;
  font-size: 15px;
  font-weight: bold;
  text-align: center;

  @media only screen and (max-width: 480px) {
    font-size: 10px;
    text-align: center;
  }
 `;

 const Para = styled.div`
  
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  color: black;
  margin-top : 5px;
  font-size: 20px;
  

  @media only screen and (max-width: 480px) {
    font-size: 10px;
    text-align: center;
  }
 `;

 const HeadingText = styled.h1 `
 `;

 const ParaText = styled.h3 `
   font-family: 'Roboto', sans-serif;
   font-weight: bolder;
`;

const Downloadicon = styled(MdFileDownload)`
  font-size: 30px;
  margin-top: 2px;
  color: yellow;
`;

const Button = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color:  #00008B;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  text-decoration: none;
  cursor: pointer;
  margin-top: 0.5rem;
`;

const ButtonText = styled.span`
  margin-right: 10px;
`;




const About = () => {
  return (
    <AboutWrapper>
       <Heading>
        <HeadingText>About Us</HeadingText>
       </Heading>

       <Para>
        <ParaText>Introducing Aakash Deep Logistics, a unit of Aakash Deep Packers and Movers. With a widespread presence across India and a robust network of over 100 branches, we offer seamless relocation and logistics solutions. As registered members of IMA (INDIAN MOVERS ASSOCIATION) and holding an ISO 9001-2015 certification, we assure top-notch quality and professionalism in every service. From packing and moving to warehousing and 3PL, trust us for efficient, safe, and economical solutions tailored to your needs.</ParaText>
       </Para>

       <Button href={Profile} download> 
        <ButtonText>Download Company Profile</ButtonText>
        <Downloadicon />
       </Button>

       

    </AboutWrapper>
  )
}

export default About
